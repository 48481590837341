import React, { useEffect, useState } from 'react'
import { IcdCardDataProps } from './ApproachCardsData'
import styled from 'styled-components'
import './collapse.scss'
import Arrow from '../../assets/blueArrow.svg'

const ExtraContent = styled.div<{ height: string; isOpen: boolean }>`
  max-height: ${({ height }) => height};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    width: 550px;
  }

  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: white;

  max-width: 550px;
  margin: 0 auto;
  margin-top: 10px;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 20px 20px 50px 20px;
  position: relative;
  background-color: white;
  box-shadow: rgb(198, 198, 198) 0px 0px 11px 0px;
  border-radius: 8px;
  width: 100%;
  min-height: 300px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    display: flex;
    flex-direction: row;
    min-height: 160px;
    max-width: 800px;
    margin-top: 40px;
  }
`

const Button = styled.button<{ isOpen: boolean }>`
  height: fit-content;
  padding: 0;
  position: absolute;
  bottom: 30px;
  margin: auto;
  left: 0;
  right: 0;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    left: unset;
    margin: unset;
    right: 40px;
    top: 80px;
  }

  img {
    display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
  }

  hr {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    width: 16px;
    margin-right: 3px;
  }
`

const Content = styled.div`
  display: flex;
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    display: block;
    margin-right: 20px;
  }

  img {
    max-width: 77px;
    object-fit: contain;
  }
`

const Text = styled.div<{ isOpen: boolean }>`
  display: flex;
  text-align: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    width: 550px;
    text-align: left;
    display: block;
  }

  p {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: ${({ theme }) => theme.fontSize.s20};

    color: ${({ isOpen, theme }) => isOpen && theme.colors.blue};

    @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
      font-size: ${({ theme }) => theme.fontSize.s22};
    }
  }
`

export const ApproachCard: React.FC<IcdCardDataProps> = ({
  icon,
  title,
  text,
  extraText,
  id,
  isOpenDefault,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault)
  const [y, setY] = useState<number | undefined>(0)

  useEffect(() => {
    const elmnt = document.getElementById(id)
    const y = elmnt?.scrollHeight
    setY(y)
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Card
      data-sal="slide-down"
      data-sal-duration="1000"
      data-sal-delay="100"
      data-sal-easing="easeOutQuad"
    >
      <Icon>
        <img src={icon} alt={'icon'} />
      </Icon>

      <Content>
        <Text isOpen={isOpen}>
          <p>{title}</p>
          {text}
          <ExtraContent
            isOpen={isOpen}
            height={isOpen ? `${y && y + 100}px` : '0px'}
            id={id}
          >
            {extraText}
          </ExtraContent>
        </Text>
      </Content>

      <Button isOpen={isOpen} data-id={id} onClick={toggle}>
        <img src={Arrow} alt={'icon'} />
        <hr />
      </Button>
    </Card>
  )
}
