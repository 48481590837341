import React from 'react'
import PentIcon from '../../assets/pentIcon4.svg'
import styled from 'styled-components'

const Triangle = styled.div<{ isActive: boolean }>`
  img {
    display: ${({ isActive }) => (isActive ? 'unset' : 'none')};
    position: absolute;
    width: 40px;
    left: 155px;
    top: 285px;
    z-index: 1;
  }

  span {
    pointer-events: none;
    color: white;
    position: absolute;
    width: 100px;
    text-align: center;
    left: ${({ isActive }) => (isActive ? '120px' : '110px')};
    top: ${({ isActive }) => (isActive ? '320px' : '300px')};
    transition: all 0.3s ease 0s;
  }
`

export const Triangle4: React.FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  return (
    <Triangle isActive={isActive}>
      <span>Expand Your Technology Offerings</span>
      <img src={PentIcon} alt={'icon'} />
    </Triangle>
  )
}
