import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '../StyledComponents/Button'
import { graphql, useStaticQuery } from 'gatsby'
import TextScramble from '../../utils/textScramble.js'
import { ModalContext } from '@/components/layout'
import { StaticImage } from 'gatsby-plugin-image'

export const StopStartSection: React.FC = () => {
  const {
    allContentfulStopStartSection: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulStopStartSection {
        nodes {
          bold1
          bold3
          bold2
          text1
          text2
          words {
            words
          }
        }
      }
    }
  `)

  const { bold1, bold3, bold2, text1, text2, words } = nodes[0]
  const toggleModal = useContext(ModalContext)

  useEffect(() => {
    const phrases = words.words

    const el = document.querySelector('.textScramble')
    const fx = new TextScramble(el)

    let counter = 0
    const next = () => {
      fx.setText(phrases[counter]).then(() => {
        setTimeout(next, 3000)
      })
      counter = (counter + 1) % phrases.length
    }
    next()
  })

  return (
    <Wrapper>
      <Content>
        <TextWrapper>
          <Text
          // data-sal="slide-down"
          // data-sal-duration="1000"
          // data-sal-easing="easeOutQuad"
          >
            <span style={{ fontWeight: 'bold' }}>{bold1} </span>
            {text1}{' '}
            <span
              style={{ fontWeight: 'bold' }}
              className="textScramble"
            />
          </Text>
          <Text
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-easing="easeOutQuad"
          >
            <span style={{ fontWeight: 'bold' }}>{bold2} </span>
            {text2} <span style={{ fontWeight: 'bold' }}>{bold3}.</span>
          </Text>
        </TextWrapper>
        <StyledButton
          onClick={toggleModal as () => void}
          primary
          // data-sal="slide-right"
          // data-sal-duration="1000"
          // data-sal-easing="easeOutQuad"
        >
          Get a Demo
        </StyledButton>
      </Content>

      <div>
        <StaticImage
          src={'../../assets/demoCta.png'}
          alt={'demoCta'}
          placeholder={'blurred'}
          quality={50}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 8%;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .gatsby-image-wrapper {
    @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
      z-index: -1;
      position: absolute;
      right: 10%;
      top: 25%;
    }

    @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
      right: 10%;
      top: 19%;
    }
  }

  img {
    @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
      max-width: 550px !important;
    }

    max-width: 350px !important;
  }
`

const Content = styled.div`
  margin: auto;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
  }
`

const TextWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    max-width: 70%;
  }
`
const Text = styled.div`
  color: white;
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    font-size: ${({ theme }) => theme.fontSize.semiTitle};
  }
`

const StyledButton = styled(Button)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue};
  :hover {
    background-color: #dfecff;
    color: #0043a7;
  }
`
