import * as React from 'react'
import Layout from '../components/layout'
import { Hero } from '../components/Hero'
import { WelcomeSection } from '../components/WelcomeSection'
import { TestimonialSection } from '../components/TestimonialSection'
import { ApproachSection } from '../components/ApproachSection'
import { Pentagon } from '../components/Pentagon'
import { Cases } from '../components/Cases'
import { CTA } from '../components/CTA'
import { StopStartSection } from '../components/StopStartSection'
import { Footer } from '../components/Footer'
import { PartnersSection } from '../components/PartnersSection'

const IndexPage: React.FC = () => {
  return (
    <>
      <Layout>
        <Hero />
        <WelcomeSection />
        {/*<TestimonialSection />*/}
        <Pentagon />
        <ApproachSection />
        <PartnersSection />
        {/*<CTA />*/}
        <Cases />
        <StopStartSection />
        <Footer />
      </Layout>
    </>
  )
}

export default IndexPage
