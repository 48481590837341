import React from 'react'
import PentIcon from '../../assets/pentIcon1.svg'
import styled from 'styled-components'

const Triangle = styled.div<{ isActive: boolean }>`
  img {
    display: ${({ isActive }) => (isActive ? 'unset' : 'none')};
    position: absolute;
    width: 40px;
    left: 170px;
    top: 80px;
    z-index: 1;
  }

  span {
    pointer-events: none;
    color: white;
    position: absolute;
    width: 69px;
    text-align: center;
    left: ${({ isActive }) => (isActive ? '216px' : '193px')};
    top: ${({ isActive }) => (isActive ? '76px' : '100px')};
    transition: all 0.3s ease 0s;
  }
`

export const Triangle1: React.FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  return (
    <Triangle isActive={isActive}>
      <span>Efficient Business & Cyber Operations</span>
      <img src={PentIcon} alt={'icon'} />
    </Triangle>
  )
}
