import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import theme from '@/utils/theme'
import SemiTitle from '@/components/UI/SemiTitle'
import ContentContainer from '@/components/UI/ContentContainer'
const Wrapper = styled(ContentContainer)<{ isBackgroundWhite?: boolean }>`
  background-color: ${({ theme, isBackgroundWhite }) =>
    isBackgroundWhite && theme.colors.white};
  z-index: 1;
  position: relative;
`
const Card = styled.div`
  width: 220px;
  height: 110px;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 20px 0 rgba(41, 40, 73, 0.12);

  img {
    max-width: 150px;
  }
`
const Cards = styled.div`
  justify-content: center;
  display: flex;
  flex-flow: wrap;
`
const StyledTitle = styled(SemiTitle)`
  padding-bottom: 40px;
  justify-content: center;
  display: flex;
  text-align: center;
`

export const PartnersSection: React.FC<{
  isBackgroundWhite?: boolean
}> = ({ isBackgroundWhite }) => {
  const {
    allContentfulPartnersSection: { nodes },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "partnersBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allContentfulPartnersSection {
        nodes {
          title
          logoList {
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { logoList, title } = nodes[0]

  const Logos = logoList.map(
    (item: { logo: { file: { url: string } } }, key: number) => (
      <Card
        data-sal="slide-right"
        data-sal-duration="1000"
        data-sal-delay={`${200 * key}`}
        data-sal-easing="easeOutQuad"
      >
        <img src={item.logo.file.url} alt={'icon'} />
      </Card>
    )
  )

  return (
    <BackgroundImageWrapper
      image={!isBackgroundWhite && backgroundImage.childImageSharp.fluid}
      backgroundColor={
        isBackgroundWhite ? theme.colors.white : theme.colors.lightGray
      }
    >
      <Wrapper isBackgroundWhite={isBackgroundWhite}>
        <StyledTitle
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="easeOutQuad"
        >
          {title}
        </StyledTitle>
        <Cards>{Logos}</Cards>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}
