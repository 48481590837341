import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PentagonSvg from '../../images/pentagon.svg'
import { Triangle1 } from './Triangle1'
import { Triangle2 } from './Triangle2'
import { Triangle3 } from './Triangle3'
import { Triangle4 } from './Triangle4'
import { Triangle5 } from './Triangle5'
import { Button } from '../StyledComponents/Button'
import { graphql, useStaticQuery } from 'gatsby'
import { ModalContext } from '@/components/layout'

const Wrapper = styled.div`
  background-color: white;
  height: 1050px;

  @media (min-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    height: 1050px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    height: 800px;
  }

  padding: 3% 0;
  z-index: 1;
  position: relative;
`

const TextWrapper = styled.div`
  padding-bottom: 30px;
  padding-top: 50px;
  text-align: center;
  max-width: 70%;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Title = styled.div`
  width: 610px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.s25};
`

const SlidesSection = styled.div`
  position: relative;

  left: 0;
  text-align: center;
  top: -100px;
  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    top: 200px;
    text-align: left;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    margin-block: 50px auto;
    top: -20px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    position: unset;
  }

  //position: relative;
`

const Slides = styled.div`
  width: 600px;
  margin: auto;

  img {
    height: 40px;
    width: auto;

    @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
      height: 50px;
    }
  }

  p.title {
    margin: 20px 0;
    font-family: ${({ theme }) => theme.fonts.semiBold};
    font-size: ${({ theme }) => theme.fontSize.title};

    @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
      font-size: ${({ theme }) => theme.fontSize.smallTitle};
    }
  }

  p {
    @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
      max-width: 370px;
    }
  }

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    width: auto;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    margin-left: 150px;
  }

  @media (min-width: 1500px) {
    margin-left: 200px;
  }
`

const DotsColumn = styled.div`
  flex-direction: row;
  display: flex;
  position: absolute;
  left: 0;
  top: -50px;
  justify-content: center;
  right: 0;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    top: 130px;
    flex-direction: column;
  }
`

const Dots = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  margin-inline: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.darkBlue : theme.colors.lightGray};
  transition: border-color 0.2s ease-out;

  :hover {
    border-color: ${({ theme }) => theme.colors.offWhite};
    box-shadow: none;
  }

  :last-of-type {
    margin-right: 0;
  }
  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    margin-bottom: 10px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    margin-left: 150px;
    margin-top: 30px;
  }

  @media (min-width: 1500px) {
    margin-left: 200px;
  }
`

const PentagonImage = styled.div<{ activeSlide: number }>`
  font-size: 13px;
  font-weight: bold;
  position: relative;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    place-self: center;
    margin: 0;
    top: -100px;
    transform: scale(0.6);
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    margin: auto;
    top: -50px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    top: 0;
  }

  .triangles {
    cursor: pointer;
  }
  .pentagon {
    :hover {
      opacity: 0;
      transition: all 0.3s ease-in-out 0s;
    }
  }

  ${({ activeSlide }) =>
    activeSlide === 0
      ? css`
          .pent1 {
            opacity: 0;
            cursor: auto;
          }
        `
      : activeSlide === 1
      ? css`
          .pent2 {
            opacity: 0;
            cursor: auto;
          }
        `
      : activeSlide === 2
      ? css`
          .pent3 {
            opacity: 0;
            cursor: auto;
          }
        `
      : activeSlide === 3
      ? css`
          .pent4 {
            opacity: 0;
            cursor: auto;
          }
        `
      : activeSlide === 4 &&
        css`
          .pent5 {
            opacity: 0;
            cursor: auto;
          }
        `}

  svg {
    z-index: -1;
  }

  button {
    position: absolute;
    font-size: ${({ theme }) => theme.fontSize.smallText};
    width: 80px;
    height: 25px;
    padding: 2px 4px 3px 5px;
    border-radius: 1.7px;
    border: solid 1px #ffffff;
    color: white;
  }
`

const Content = styled.div`
  display: flex;
  margin: 0 5%;
  position: relative;

  @media (max-width: ${({ theme }) => theme.rwd.tablet.maxWidth}) {
    flex-direction: column-reverse;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
    margin: 0 10%;
    flex-direction: row;
  }

  @media (min-width: 1750px) {
    margin: 0 16%;
  }
`

type SlideGraphqlData = {
  text: string
  title: string
  icon: { file: { url: string } }
}
export const Pentagon: React.FC = () => {
  const {
    allContentfulPentagonSection: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulPentagonSection {
        nodes {
          title
          button {
            text
          }
          slide {
            text
            title
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const [currentSlide, setCurrentSlide] = useState(0)
  const { slide, title, button } = nodes[0]
  const toggleModal = useContext(ModalContext)

  const changeSlide = (slide: number) => {
    setCurrentSlide(slide)
  }

  useEffect(() => {
    const button = document.querySelectorAll('.pentagon')

    for (let i = 0; i < button.length; i++) {
      button[i].addEventListener('click', () => {
        changeSlide(i)
      })
    }
  }, [])

  const slides = slide.map((item: SlideGraphqlData) => (
    <Slides>
      <img src={item.icon.file.url} alt={'image'} />
      <p className="title">{item.title}</p>
      <p>{item.text}</p>
    </Slides>
  ))

  return (
    <Wrapper>
      <TextWrapper>
        <Title
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="100"
          data-sal-easing="easeOutQuad"
        >
          {title}
        </Title>
      </TextWrapper>

      <Content>
        <SlidesSection>
          <DotsColumn>
            {Array.from({ length: 5 }, (_, index) => (
              <Dots
                isActive={index === currentSlide}
                onClick={() => changeSlide(index)}
              />
            ))}
          </DotsColumn>
          {slides[currentSlide]}

          <StyledButton onClick={toggleModal as () => void} primary>
            {button.text}
          </StyledButton>
        </SlidesSection>

        <PentagonImage activeSlide={currentSlide}>
          <Triangle1 isActive={currentSlide === 0} />
          <Triangle2 isActive={currentSlide === 1} />
          <Triangle3 isActive={currentSlide === 2} />
          <Triangle4 isActive={currentSlide === 3} />
          <Triangle5 isActive={currentSlide === 4} />
          <PentagonSvg />
        </PentagonImage>
      </Content>
    </Wrapper>
  )
}
