import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { IcdCardGraphqlData } from './ApproachCardsData'
import { ApproachCard } from './ApproachCard'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import theme from '@/utils/theme'
import SemiTitle from '@/components/UI/SemiTitle'
import ContentContainer from '@/components/UI/ContentContainer'

const TextWrapper = styled.div`
  padding-bottom: 30px;
  text-align: center;
`

const StyledTitle = styled(SemiTitle)`
  max-width: 1222px;
  margin: auto;
  font-size: ${({ theme }) => theme.fontSize.s25};
`
const Cards = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

type Props = {
  sectionTitle?: string
  styles?: CSSProperties
}

export const ApproachSection: React.FC<Props> = ({
  sectionTitle,
  styles,
}) => {
  const {
    allContentfulIcdSection: { nodes },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "defendYourDataBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allContentfulIcdSection {
        nodes {
          title
          cardsList {
            id
            title
            text
            extraText
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { title, cardsList } = nodes[0]

  const finalTitle = sectionTitle ?? title

  const ApproachesCards = cardsList.map(
    (item: IcdCardGraphqlData, key: number) => (
      <ApproachCard
        key={item.id}
        icon={item.icon?.file.url}
        isOpenDefault={key === 0}
        id={item.id}
        title={item.title}
        text={item.text}
        extraText={item.extraText}
      />
    )
  )

  return (
    <BackgroundImageWrapper
      styles={{ backgroundPositionY: 200, backgroundSize: 'contain' }}
      image={backgroundImage.childImageSharp.fluid}
      backgroundColor={theme.colors.lightGray}
    >
      <ContentContainer styles={styles}>
        <TextWrapper>
          <StyledTitle
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="easeOutQuad"
          >
            {finalTitle}
          </StyledTitle>
        </TextWrapper>
        <Cards>{ApproachesCards}</Cards>
      </ContentContainer>
    </BackgroundImageWrapper>
  )
}
