import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useWindowSize } from '../../hooks'
import { Button } from '../StyledComponents/Button'
import { graphql, useStaticQuery } from 'gatsby'

import { ModalContext } from '@/components/layout'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'

import theme from '@/utils/theme'
import { StaticImage } from 'gatsby-plugin-image'
import MainTowerSvg from '@/images/HPtower.svg'

const Wrapper = styled.div<{ height: string }>`
  position: relative;
  z-index: 1;
  padding: 5% 8%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 901px) {
    display: block;
    height: 736px;
  }

  @media (max-width: 900px) {
    height: 750px;
  }

  @media (max-width: 500px) {
    height: 900px;
  }

  @media (max-width: 350px) {
    height: 1000px;
  }
`

const MainTower = styled.div`
  position: absolute;
  width: 250px;
  right: 30%;
  bottom: 133px;

  @media (max-width: ${({ theme }) => theme.rwd.tablet.m}) {
    left: -50px;
  }

  @media (max-width: 700px) {
    left: -200px;
  }
`

const BackupTowerStyle = styled.div<{
  isHover: boolean
  currentHover: number
}>`
  position: absolute;
  width: 116px;
  right: 10%;
  bottom: 395px;

  @media (max-width: ${({ theme }) => theme.rwd.tablet.m}) {
    right: -100px;
  }

  .main {
    display: ${({ isHover }) => (isHover ? 'none' : 'inline-block')};
  }

  .blue {
    display: none;

    top: 0;
    left: 0;
    z-index: 99;
  }

  .gray {
    display: ${({ currentHover, isHover }) =>
      isHover && currentHover !== 0 ? 'inline-block' : 'none'};
    top: 0;
    left: 0;
    z-index: 99;
  }

  :hover {
    .blue {
      display: inline-block;
      //opacity: 1;
      //width: inherit;
      //transition: opacity ease-in-out 0.3s;
    }
    .main {
      display: none;
    }
  }

  span {
    display: none;
    top: -35px;
    right: 25px;
    position: absolute;
  }

  :hover span {
    display: inline;
  }
`
const WebTowerStyle = styled.div<{
  isHover: boolean
  currentHover: number
}>`
  position: absolute;
  right: 20%;
  bottom: 309px;
  width: 125px;

  .main {
    display: ${({ isHover }) => (isHover ? 'none' : 'inline-block')};
  }

  .blue {
    display: none;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .gray {
    display: ${({ currentHover, isHover }) =>
      isHover && currentHover !== 1 ? 'inline-block' : 'none'};
    top: 0;
    left: 0;
    z-index: 99;
  }

  :hover .blue {
    display: inline-block;
    .main {
      display: none;
    }
  }

  span {
    display: none;
    top: -35px;
    right: 35px;
    position: absolute;
  }

  :hover {
    span {
      display: inline;
    }
  }
`
const MailTowerStyle = styled.div<{
  isHover: boolean
  currentHover: number
}>`
  position: absolute;
  width: 144px;
  right: 7%;
  bottom: 206px;

  @media (max-width: ${({ theme }) => theme.rwd.tablet.m}) {
    right: -100px;
  }

  .main {
    display: ${({ isHover }) => (isHover ? 'none' : 'inline-block')};
  }

  .blue {
    display: none;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .gray {
    display: ${({ currentHover, isHover }) =>
      isHover && currentHover !== 2 ? 'inline-block' : 'none'};
    top: 0;
    left: 0;
    z-index: 99;
  }

  :hover {
    .blue {
      display: inline-block;
    }
    .main {
      display: none;
    }
  }

  span {
    display: none;
    top: -35px;
    right: 55px;
    position: absolute;
  }

  :hover {
    span {
      display: inline;
    }
  }
`
const DataTowerStyle = styled.div<{
  isHover: boolean
  currentHover: number
}>`
  position: absolute;
  width: 145px;

  right: 18%;
  bottom: 98px;

  .main {
    display: ${({ isHover }) => (isHover ? 'none' : 'inline-block')};
  }

  .blue {
    position: relative;
    top: 2px;
    left: 2px;
    width: 146px;
    height: 215px;
    display: none;
    z-index: 99;
  }

  .gray {
    display: ${({ currentHover, isHover }) =>
      isHover && currentHover !== 3 ? 'inline-block' : 'none'};
    top: 0;
    left: 0;
    z-index: 99;
  }

  :hover {
    .blue {
      display: inline-block;
    }

    .main {
      display: none;
    }
  }

  span {
    display: none;
    top: -35px;
    right: 55px;
    position: absolute;
  }

  :hover {
    span {
      display: inline;
    }
  }
`
const Towers = styled.div`
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.rwd.tablet.m}) {
    position: relative;
    display: flex;
    transform: scale(0.4);
    top: 120px;
    left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.l}) {
    transform: unset;
    display: block;
  }

  animation-duration: 0.5s;
  animation-name: fadeIn;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
const Titles = styled.div`
  padding-top: 50px;
  margin-bottom: 50px;
  max-width: 800px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    margin-bottom: 70px;
    margin-right: 50px;
  }
`
const StyledTitle = styled.h1<{ first?: boolean; second?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.semiTitle};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize.semiTitle};
  }

  animation-duration: 2s;
  animation-name: fadeInDown;
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const StyledTitle2 = styled.p`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.semiTitle};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize.title};
  }

  opacity: 0;
  animation-delay: 0.3s;
  animation-name: fadeInUp;
  animation-fill-mode: both;
  animation-duration: 2s;

  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

const Text = styled.div`
  animation: fadein 2s;
  animation-fill-mode: both;
  animation-delay: 0.4s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  letter-spacing: 1px;
  line-height: 30px;
  margin: 10px 0;

  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
    max-width: 600px;
  }

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    font-size: ${({ theme }) => theme.fontSize.text};
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    font-size: ${({ theme }) => theme.fontSize.bigText};
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    font-size: ${({ theme }) => theme.fontSize.s20};
  }
`

const StyledButton = styled(Button)`
  margin: 20px 0;
  animation: fadeInLeft 1s;
  animation-delay: 0.6s;
  animation-fill-mode: both;

  @keyframes fadeInLeft {
    from {
      opacity: 0;

      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const Hero: React.FC = () => {
  const { height } = useWindowSize()
  const [isHover, setIsHover] = useState(false)
  const [currentHover, setCurrentHover] = useState<number>(0)

  const toggleModal = useContext(ModalContext)

  const {
    allContentfulHeroSection: { edges },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "heroBg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulHeroSection {
        edges {
          node {
            title1
            title2
            text1new {
              text1new
            }
            text2new {
              text2new
            }
            backupTooltip
            webTooltip
            mailTooltip
            dataTooltip
            button {
              text
            }
          }
        }
      }
    }
  `)
  const {
    title1,
    title2,
    text1new,
    text2new,
    backupTooltip,
    webTooltip,
    mailTooltip,
    dataTooltip,
    button,
  } = edges[0].node

  return (
    <BackgroundImageWrapper
      styles={{ backgroundPosition: 'bottom' }}
      image={backgroundImage.childImageSharp.fluid}
      backgroundColor={theme.colors.lightGray}
    >
      <Wrapper height={`${height - 70}px`}>
        <Towers>
          <MainTower>
            <MainTowerSvg />
          </MainTower>
          <BackupTowerStyle
            onMouseOver={() => {
              setIsHover(true)
              setCurrentHover(0)
            }}
            onMouseLeave={() => setIsHover(false)}
            isHover={isHover}
            currentHover={currentHover}
          >
            <span className={'text'}>{backupTooltip}</span>
            <StaticImage
              className={'main'}
              src={'../../assets/backupTower.png'}
              alt={'backupTower'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'blue'}
              src={'../../assets/backupTowerBlue.png'}
              alt={'blue'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'gray'}
              src={'../../assets/backupTowerGray.png'}
              alt={'gray'}
              placeholder={'blurred'}
              quality={50}
            />
          </BackupTowerStyle>
          <WebTowerStyle
            isHover={isHover}
            currentHover={currentHover}
            onMouseOver={() => {
              setIsHover(true)
              setCurrentHover(1)
            }}
            onMouseLeave={() => setIsHover(false)}
          >
            <span className={'text'}>{webTooltip}</span>
            <StaticImage
              className={'main'}
              src={'../../assets/webTower.png'}
              alt={'webTower'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'blue'}
              src={'../../assets/webTowerBlue.png'}
              alt={'blue'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'gray'}
              src={'../../assets/webTowerGray.png'}
              alt={'gray'}
              placeholder={'blurred'}
              quality={50}
            />
          </WebTowerStyle>
          <MailTowerStyle
            isHover={isHover}
            currentHover={currentHover}
            onMouseOver={() => {
              setIsHover(true)
              setCurrentHover(2)
            }}
            onMouseLeave={() => setIsHover(false)}
          >
            <span className={'text'}>{mailTooltip}</span>
            <StaticImage
              className={'main'}
              src={'../../assets/mailTower.png'}
              alt={'mailTower'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'blue'}
              src={'../../assets/mailTowerBlue.png'}
              alt={'blue'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'gray'}
              src={'../../assets/mailTowerGray.png'}
              alt={'gray'}
              placeholder={'blurred'}
              quality={50}
            />
          </MailTowerStyle>
          <DataTowerStyle
            isHover={isHover}
            currentHover={currentHover}
            onMouseOver={() => {
              setIsHover(true)
              setCurrentHover(3)
            }}
            onMouseLeave={() => setIsHover(false)}
          >
            <span className={'text'}>{dataTooltip}</span>
            <StaticImage
              className={'main'}
              src={'../../assets/dataTower.png'}
              alt={'dataTower'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'blue'}
              src={'../../assets/dataTowerBlue.png'}
              alt={'blue'}
              placeholder={'blurred'}
              quality={50}
            />
            <StaticImage
              className={'gray'}
              src={'../../assets/dataTowerGray.png'}
              alt={'gray'}
              placeholder={'blurred'}
              quality={50}
            />
          </DataTowerStyle>
        </Towers>

        <Titles>
          <StyledTitle>{title1}</StyledTitle>
          <StyledTitle2>{title2}</StyledTitle2>
        </Titles>
        <Text>
          <p>{text1new.text1new}</p>
          <br />
          <p>{text2new.text2new}</p>
        </Text>
        <div>
          <StyledButton primary onClick={toggleModal as () => void}>
            {button.text}
          </StyledButton>
        </div>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}
