import React from 'react'
import PentIcon from '../../assets/pentIcon2.svg'
import styled from 'styled-components'

const Triangle = styled.div<{ isActive: boolean }>`
  img {
    display: ${({ isActive }) => (isActive ? 'unset' : 'none')};
    position: absolute;
    width: 40px;
    left: 310px;
    top: 160px;
    z-index: 1;
  }

  span {
    pointer-events: none;

    cursor: pointer;
    color: white;
    position: absolute;
    width: 90px;
    text-align: center;
    left: ${({ isActive }) => (isActive ? '285px' : '280px')};
    top: ${({ isActive }) => (isActive ? '190px' : '190px')};
    transition: all 0.3s ease 0s;
  }
`

export const Triangle2: React.FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  return (
    <Triangle isActive={isActive}>
      <span>Automated Mitigation</span>
      <img src={PentIcon} alt={'icon'} />
    </Triangle>
  )
}
