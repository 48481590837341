import React from 'react'
import PentIcon1 from '../../assets/pentIcon5.svg'
import styled from 'styled-components'

const Triangle = styled.div<{ isActive: boolean }>`
  img {
    display: ${({ isActive }) => (isActive ? 'unset' : 'none')};
    position: absolute;
    width: 40px;
    left: 100px;
    top: 135px;
    z-index: 1;
  }

  span {
    pointer-events: none;
    color: white;
    position: absolute;
    width: 100px;
    text-align: center;
    left: ${({ isActive }) => (isActive ? '70px' : '90px')};
    top: ${({ isActive }) => (isActive ? '190px' : '190px')};
    transition: all 0.3s ease 0s;
  }
`

export const Triangle5: React.FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  return (
    <Triangle isActive={isActive}>
      <span>Cross-client Threat Prevention</span>
      <img src={PentIcon1} alt={'icon'} />
    </Triangle>
  )
}
