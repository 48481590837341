import React from 'react'
import styled from 'styled-components'
import ContentContainer from '../UI/ContentContainer'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import theme from '@/utils/theme'
import SemiTitle from '@/components/UI/SemiTitle'
import { StaticImage } from 'gatsby-plugin-image'
import { useWindowSize } from '@/hooks'

export const WelcomeSection: React.FC = () => {
  const {
    allContentfulWeAreFortressSection: { nodes },
    backgroundImage: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "weAreFortress.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allContentfulWeAreFortressSection {
        nodes {
          title
          text
        }
      }
    }
  `)
  const { title, text } = nodes[0]
  const { width } = useWindowSize()

  return (
    <BackgroundImageWrapper
      image={fluid}
      backgroundColor={theme.colors.gray}
    >
      {width < 380 && (
        <StaticImage
          src={'../../assets/weAreFortressMobileBg.png'}
          alt={'bg'}
        />
      )}
      <ContentContainer padding={'100px 5%'}>
        <TextWrapper>
          <StyledTitle
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="100"
            data-sal-easing="easeOutQuad"
          >
            {title}
          </StyledTitle>
          <Text>
            <p
              data-sal="zoom-in"
              data-sal-duration="1000"
              data-sal-delay="100"
              data-sal-easing="easeOutQuad"
            >
              {text}
            </p>
          </Text>
        </TextWrapper>
      </ContentContainer>
    </BackgroundImageWrapper>
  )
}

const TextWrapper = styled.div`
  text-align: center;
  margin-inline: auto;
`
const StyledTitle = styled(SemiTitle)`
  margin-bottom: 20px;
`
const Text = styled.div`
  letter-spacing: 1px;
  line-height: 30px;
  max-width: 600px;
  margin-inline: auto;
`
