import React, { CSSProperties, ReactChildren, ReactElement } from 'react'
import styled from 'styled-components'

type Props = {
  styles?: CSSProperties
  padding?: string
  children: ReactChildren | ReactElement | JSX.Element[]
}

const ContentContainer: React.FC<Props> = (props) => {
  const { children, padding, styles } = props

  return (
    <Wrapper {...props} style={styles} padding={padding}>
      {children}
    </Wrapper>
  )
}

export const Wrapper = styled.div<{ padding?: string }>`
  padding: 40px 20px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    padding: ${({ padding }) => (padding ? padding : '50px 8%')};
  }
`

export default ContentContainer
